import React from 'react';
import { Content } from '@carbon/react';

function Values() {
  return (
    <Content>
      <h1>Values</h1>
      <p>This is the values page.</p>
    </Content>
  );
}

export default Values;