import React from 'react';
import { Content } from '@carbon/react';

function About() {
  return (
    <Content>
      <h1>About</h1>
      <p>This is the about page.</p>
    </Content>
  );
}

export default About;