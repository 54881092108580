import React from 'react';
import { Content } from '@carbon/react';

function Home() {
  return (
    <Content>
      <h1>Home</h1>
      <p>This is the home page.</p>
    </Content>
  );
}

export default Home;