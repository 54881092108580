import React from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderGlobalBar,
  HeaderGlobalAction,
} from '@carbon/react';
import '@carbon/styles/css/styles.css'; // Import Carbon CSS

function AppHeader({ isOpen, setIsOpen, activePage }) {
  return (
    <Header style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
      <HeaderName href="#" prefix="Neon" style={{ marginLeft: '1rem' }}>
        {activePage}
      </HeaderName>
      <HeaderNavigation aria-label="Neon">
      </HeaderNavigation>
      <HeaderGlobalBar>
        <HeaderGlobalAction
          aria-label="Close switcher"
          aria-labelledby="tooltip-:rj:"
          id="switcher-button"
          aria-expanded="true"
          className="cds--btn--icon-only cds--header__action cds--header__action--active cds--btn cds--btn--primary"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          style={{ marginRight: '32pt', position: 'sticky'}}
        >
          <svg
            focusable="false"
            preserveAspectRatio="xMidYMid meet"
            fill="currentColor"
            width="20"
            height="20"
            viewBox="0 0 32 32"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14 4H18V8H14zM4 4H8V8H4zM24 4H28V8H24zM14 14H18V18H14zM4 14H8V18H4zM24 14H28V18H24zM14 24H18V28H14zM4 24H8V28H4zM24 24H28V28H24z"></path>
          </svg>
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </Header>
  );
}

export default AppHeader;