import React, { useState } from 'react';
import { Content } from '@carbon/react';
import { magenta } from '@carbon/colors';
import AppHeader from './Header';
import AppSideNav from './SideNav';
import Home from './Home'
import Journey from './Journey';
import Values from './Values';
import Technical from './Technical';
import About from './About';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [activePage, setActivePage] = useState('Home');

  const renderPage = () => {
    switch(activePage) {
      case 'Home':
        return <Home />;
      case 'Journey':
        return <Journey />;
      case 'Values':
        return <Values />;
      case 'Technical':
        return <Technical />;
      case 'About':
        return <About />;
      default:
        return <Home />; // default to Home page
    }
  }

  return (
    <div className="App">
      <AppHeader isOpen={isOpen} setIsOpen={setIsOpen} activePage={activePage} />
      <Content>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '0 0 auto' }}>
            <AppSideNav isOpen={isOpen} setActivePage={setActivePage} />
          </div>
          <div style={{ flex: '1 1 auto' }}>
            {renderPage()}
          </div>
        </div>
      </Content>
      <style>{`
        body {
          background-color: ${magenta[10]};
        }
      `}</style>
    </div>
  );
}

export default App;