// SideNav.js
import React from 'react';
import {
  SideNav,
  SideNavItems,
  SideNavLink,
} from '@carbon/react';

function AppSideNav({ isOpen, setActivePage }) {
  return (
    <SideNav isFixedNav expanded={isOpen} isChildOfHeader={true} aria-label="Side navigation">
      <SideNavItems>
        <SideNavLink className="sidemenuitems" onClick={() => setActivePage('Home')}>Home</SideNavLink>
        <SideNavLink className="sidemenuitems" onClick={() => setActivePage('Journey')}>Journey</SideNavLink>
        <SideNavLink className="sidemenuitems" onClick={() => setActivePage('Values')}>Values</SideNavLink>
        <SideNavLink className="sidemenuitems" onClick={() => setActivePage('Technical')}>Technical</SideNavLink>
        <SideNavLink className="sidemenuitems" onClick={() => setActivePage('About')}>About</SideNavLink>
      </SideNavItems>
    </SideNav>
  );
}

export default AppSideNav;