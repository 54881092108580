import React from 'react';
import { Content } from '@carbon/react';

function Journey() {
  return (
    <Content>
      <h1>Journey</h1>
      <p>This is the journey page.</p>
    </Content>
  );
}

export default Journey;